import {
  LogData,
  LogicalBot,
  RpaDashboard,
  RpaDashboardResponse,
  SignOffData,
  SignOffDataResponse,
  SignOffHistoryItem,
  SubRecord,
} from '@/types/om27.types';
import defaults from 'lodash/defaults';

// --------------------------------------------
// All of this is because the swagger spec lies
// and we never know what is going to come back
// missing or null
// --------------------------------------------

export function dashboardDefaults(data: RpaDashboardResponse): RpaDashboard {
  const logicalBots = data.logicalBots.map(logicalBot => {
    const botOut = defaultLogicalBot(logicalBot);
    botOut.subRecords = botOut.subRecords.map(defaultSubRecord);
    return botOut;
  });
  return {
    ...data,
    logicalBots,
  };
}

export function signOffDefaults(data: SignOffDataResponse): SignOffData {
  return defaultSignOffData(data);
}

export function signOffHistoryDefaults(data: Partial<SignOffHistoryItem>[]): SignOffHistoryItem[] {
  return data
    .map(item => defaultSignOffHistoryItem(item))
    .sort((a, b) => new Date(b.closureDate).getTime() - new Date(a.closureDate).getTime());
}

export function defaultLogData(data: Partial<LogData>[]): LogData[] {
  return data.map(d => defaults(d, logDataTemplate()));
}

function defaultSignOffData(data: SignOffDataResponse): SignOffData {
  const logData = {
    logData: defaultLogData(data.logData ?? []),
  };
  return Object.assign(defaults(data, signOffDataTemplate()), logData);
}
function defaultLogicalBot(bot: Partial<LogicalBot>): LogicalBot {
  return defaults(bot, logicalBotTemplate());
}
function defaultSubRecord(bot: Partial<SubRecord>): SubRecord {
  return defaults(bot, subRecordTemplate());
}
function defaultSignOffHistoryItem(item: Partial<SignOffHistoryItem>): SignOffHistoryItem {
  return defaults(item, signOffHistoryItemTemplate());
}

function signOffDataTemplate(): SignOffData {
  return {
    userPreferences: {
      preferred_timezone: 'Asia/Calcutta',
      timezone_short: '',
    },
    evidenceTemplate: '',
    signoffStartDate: '',
    signoffNeededByDate: '',
    logData: [],
    blockingTickets: [],
    priorEffectiveDate: '',
    signofffor: 0,
    signofffordesc: '',
    supportTeam: '',
    files: [],
  };
}
function logicalBotTemplate(): LogicalBot {
  return {
    logicalBotId: 0,
    botName: '',
    status: '',
    sox: '',
    folderName: '',
    assignmentGroup: '',
    schedule: '',
    autoSignoffSchedule: [],
    additionalInfo: '',
    assignedUsers: [],
    triggerType: '',
    latestStartDate: '',
    latestErrorDate: '',
    latestFinishDate: '',
    currentSignOffNeededByDate: '',
    currentSignOffStartDate: '',
    signOffId: 0,
    latestErrorMsg: '',
    latestConfirmedStartDate: '',
    latestConfirmedStartMsg: '',
    latestTrustedFinishedDate: '',
    latestTrustedFinishedMsg: '',
    blockingTickets: [],
    tickets: [],
    subRecords: [],
    soxHumanApprover: '',
    soxDescription: '',
    soxDocumentReference: '',
    actionNeeded: false,
    statusTags: [null],
    validationReason: null,
    lenientAutoSignoffFlag: false,
    lenientApprover: '',
  };
}
function subRecordTemplate(): SubRecord {
  return {
    botId: 0,
    fileName: '',
    machineNameShort: '',
    botRunnerUserId: '',
    latestStartDate: '',
    latestErrorDate: '',
    latestFinishDate: '',
    latestErrorMsg: '',
    latestConfirmedStartDate: '',
    latestConfirmedStartMsg: '',
    latestTrustedFinishedDate: '',
    latestTrustedFinishedMsg: '',
    disabled: false,
  };
}
function logDataTemplate(): LogData {
  return {
    botLogId: 0,
    botId: 0,
    fileName: '',
    machineNameShort: '',
    botRunnerUserId: '',
    startDate: '',
    startMsg: '',
    errorDate: '',
    finishDate: '',
    finishMsg: '',
    errorMsg: '',
    confirmedStartDate: '',
    confirmedStartMsg: '',
    trustedFinishedDate: '',
    trustedFinishedMsg: '',
    nonNullDateField: '',
  };
}
function signOffHistoryItemTemplate(): SignOffHistoryItem {
  return {
    signoffId: 0,
    signoffEffectiveDate: '',
    blockingTickets: [],
    tickets: [],
    signoffStartDate: '',
    signoffNeededByDate: '',
    closureDate: '',
    signoffHumanUserMudId: '',
    signoffHumanUserName: '',
    evidenceDetails: '',
    evidenceType: '',
    verifyHumanUserMudId: '',
    verifyHumanUserName: '',
    lastVerificationStatus: '',
    lastVerificationStatusCode: '',
    lastVerificationNotes: '',
    lastVerificationDate: '',
    signofffor: 0,
    signofffordesc: '',
    signoffTicket: {
      ticketNumber: '',
    },
    verifyTicket: {
      ticketNumber: '',
    },
  };
}
